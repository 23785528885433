<template class="pa-0">

<v-flex class="pa-0">

                  <v-card>
                    <v-card-title>
                       Resumen por vehiculos
                      <v-divider vertical class="mx-2"></v-divider>


                        <v-flex md2 lg2 mx-4 pt-5>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                       </v-flex>

                        <v-btn dark color="success" @click="dialogInicio=true" class="mx-1">
                            <v-icon>date_range</v-icon>
                        </v-btn>


                       <vue-excel-xlsx  class="mx-1" v-show="data_excel.length >0"
                        :data="data_excel"
                        :columns="column_excel"
                        :filename="'Resumen_excesos'"
                        :sheetname="'hoja1'">
                        <v-btn color="info">
                            <v-icon dark>get_app</v-icon>
                        </v-btn>
                        
                        </vue-excel-xlsx>

                   
                           
                       <v-spacer></v-spacer>


                  

                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headersResumenVeh"
                      :items="ItemResumenVeh"
                      :search="search"
                    >


                    <template v-slot:[`item.detalle`]="{ item }">
                                    <v-icon color="indigo" @click="AbrirDetalleVeh(item)">preview</v-icon>
                    </template>

        
                    
                    </v-data-table>
                  </v-card>


                     <v-dialog v-model="dialogoVeh" max-width="850">
                    <v-card>

                      <v-toolbar color="red">
                        <v-toolbar-title class="white--text">  Detalle Excesos Vehiculo {{viewVeh.nombre}} - {{viewVeh.placa}}
                          
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                          <v-icon dark @click="dialogoVeh=false">close</v-icon>
                      
                      </v-toolbar>
                      

                      <v-card-text>


                        <v-card class="mt-5">
                              <v-card-title>
                                <v-text-field
                                  v-model="buscarVeh"
                                  append-icon="mdi-magnify"
                                  label="buscar"
                                  single-line
                                  hide-details
                                ></v-text-field>
                              </v-card-title>
                              <v-data-table
                                :headers="headersDetalleVeh"
                                :items="itemDetalleVeh"
                                :search="buscarVeh"
                              >
                              
                                  <template v-slot:[`item.fecha`]="{ item }">
                                    <span>{{ getDate(item.fecha) }}</span>
                              </template>

                                <template v-slot:[`item.ubicacion`]="{ item }">
                                  <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                </template>

                              </v-data-table>
                            </v-card>
                      
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn dark color="green darken-1" @click="dialogoVeh=false">
                          cerrar
                        </v-btn>

                      </v-card-actions>
                    </v-card>
                    </v-dialog>



                    
    <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Paradas por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>



   
                 
</v-flex>


</template>


<script>


import axios from 'axios';
import { mapMutations, mapState } from "vuex";

 export default {
    data: () => ({

        PaisesArray:[],
        id_pais_activo:'',
        search:'',

        headersResumenVeh:[ {text: 'Nombre', value: 'nombre' },
                            {text: 'Placa', value: 'placa' },
                            {text: 'Flota', value: 'flota' },
                           // {text: 'Leve', value: 'Leve' },
                            {text: 'Grave', value: 'Grave' },
                            {text: 'Critico', value: 'Critico'},
                            {text: 'Excesos Geocerca', value: 'totalGeo' },
                            {text: 'Total', value: 'total' },
                            {text: 'Detalle', value: 'detalle'}
                            ],


        ItemResumenVeh:[],



        dialogoVeh:false,
        viewVeh:{},


        buscarVeh:'',

        dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


         data_excel:[],

          column_excel:[ 
                    {label: "Vehiculo",  field: "nombre"},
                    {label: "Placa", field: "placa"},
                    {label: "Flota", field: "flota"},
                    //{label: "Leve",  field: "Leve"},
                    {label: "Grave", field: "Grave"},
                    {label: "Critico", field: "Critico"},
                    {label: "Exvesos Geocercas",  field: "totalGeo"},
                    {label: "Total", field: "total"}
                  
                    ],


        headersDetalleVeh:[
                {text:'Fecha', value:'fecha'},
                {text:'Evento', value:'evento'},
                {text:'conductor', value:'conductor'},
                {text:'Velocidad', value:'velocidad'},
                {text:'Severidad', value:'severidad'},
                {text:'Ubicacion', value:'ubicacion'},
                {text:'Direccion', value:'direccion'}

                ],

        itemDetalleVeh:[],


        ArregloExcesos:[],
        resumen_vehiculos:[],
        RespuestaPaises:[],


        arrayFlota:[],
       


    }),

      computed: {

          ...mapState(['usuario', 'token']),
      },
      
      created () {
      this.listarPaises();
    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),


           listarPaises () {

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;

         me.RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
           //  console.log(response);
             me.RespuestaPaises=response.data;

             me.RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

             me.listarFlotas();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },

                //cada flota tiene su clasificacion de severidad
         listarFlotas(){

                     this.arrayFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`flotas/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                      
                        me.arrayFlota=response.data;

                        me.ocultarLoading();
                         me.listar();

                        //  console.log(me.arrayFlota);
                    }).catch(function(error){ console.log(error); });

         },

           listar(){


             this.data_excel=[];


             let me=this;

            
             this.ArregloExcesos=[];
           
               let config={headers:{ token:this.token}};
               
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('eventos-exceso',{ 
                                'id_pais':this.id_pais_activo,
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin
                              },
                            config
                            ).then(response=>{
                            
                             //   console.log(response);
                                me.ArregloExcesos=response.data;

                                 me.resumen_vehiculos=[];

                                me.ArregloExcesos.map(function(x){



                                 

                                    
                                        let encontrado=false;
                                        me.resumen_vehiculos.map(function(y){

                                          if((y._id) && (y._id!=null) && (x.id_vehiculo) && (x.id_vehiculo!=null) && (x.velocidad > 79)){
                                                    
                                             if(x.id_vehiculo._id==y._id){


                                                    let severidad='';



                                                     if(x.tipo=='Exceso'){// si es exceso

                                                         let clasificacion=[];


                                                         if((x.id_vehiculo) && (x.id_vehiculo.flota!=null)){

                                                              //buscamos la segmentacion para esa flota de ese evento
                                                              me.arrayFlota.map(function(b){
                                                                if(b.nombre_flota==x.id_vehiculo.flota){
                                                                    clasificacion=b.clasificacion_velocidad;
                                                                }
                                                                });
                                                            }



                                                            clasificacion.map(function(z){

                                                                if((parseFloat(x.velocidad)  >= z.desde)&&(parseFloat(x.velocidad)  <=  z.hasta)){
                                                                        let campo=z.nombre;
                                                                        severidad=z.nombre;
                                                                       // console.log('valor clasificacion:'+y[campo]);

                                                                        if(!y[campo]){
                                                                          y[campo]=1;
                                                                        }else{
                                                                           y[campo]++;
                                                                        }
                                                                       
                                                                    }
                                                                    
                                                            });

                                                    }else if(x.tipo=='ExcesoGeo'){

                                                       severidad='Exceso Geocerca';
                                                       y.totalGeo++;

                                                    }//fin s es exceso de geocerca


                                            if(severidad!=''){

                                                  let detalle=y.detalle;
                                                  detalle.push({
                                                          'conductor':x.id_conductor.nombre_conductor, 
                                                          'fecha':x.fecha, 
                                                          'evento':x.evento, 'severidad':severidad, 'velocidad':x.velocidad,
                                                          'latitud':x.latitud, 'longitud': x.longitud,
                                                          'direccion':x.direccion
                                                  });

                                                  y.detalle=detalle;
                                                  y.total++;
                                                  encontrado=true;

                                             }

                                                   
                                              }//si es el mismo



                                        }//fin si el vehiculo existe
                                        });






                              if((x.id_vehiculo) && (x.id_vehiculo!=null)&& (x.velocidad > 79)){
                                        if(encontrado==false){

                                       
                                           let objeto_ingresar={'_id':x.id_vehiculo._id,'nombre':x.id_vehiculo.nombre,
                                            'placa':x.id_vehiculo.placa,'flota':x.id_vehiculo.flota};


                                            let severidad='';

                                              let clasificacion=[];


                                                         if((x.id_vehiculo) && (x.id_vehiculo.flota!=null)){

                                                              //buscamos la segmentacion para esa flota de ese evento
                                                              me.arrayFlota.map(function(b){
                                                                if(b.nombre_flota==x.id_vehiculo.flota){
                                                                    clasificacion=b.clasificacion_velocidad;
                                                                }
                                                                });
                                                            }


                                             clasificacion.map(function(z){


                                                  if(x.tipo=='Exceso'){// si es exceso

                                                            if((parseFloat(x.velocidad)  >= z.desde)&&(parseFloat(x.velocidad)  <=  z.hasta)){
                                                                let campo=z.nombre;
                                                                    objeto_ingresar[campo]=1;
                                                                    severidad=z.nombre;
                                                             // console.log('severidad:'+campo+', velocidad:'+x.velocidad);
                                                             // console.log(objeto_ingresar);
                                                                  
                                                            }else{

                                                                let campo=z.nombre;  //nombre del campo es dinamico
                                                                objeto_ingresar[campo]=0;

                                                                
                                                            }

                                                          objeto_ingresar.totalGeo=0;

                                                  }else if(x.tipo=='ExcesoGeo'){

                                                        severidad='Exceso Geocerca';
                                                        objeto_ingresar.totalGeo=1;


                                                  }



                                                });



                                                
                                            if(severidad!=''){


                                                    objeto_ingresar.detalle=[{
                                                        'conductor':x.id_conductor.nombre_conductor, 
                                                        'fecha':x.fecha, 
                                                        'evento':x.evento, 'severidad':severidad, 'velocidad':x.velocidad,
                                                        'latitud':x.latitud, 'longitud': x.longitud,
                                                        'direccion':x.direccion
                                                        }];

                                                    objeto_ingresar.total=1;

                                                    

                                                me.resumen_vehiculos.push(objeto_ingresar);

                                            }
                                         
                                        }

              }//fin si el vehiculo existe



                                

                               

                                });

                                 me.ItemResumenVeh=me.resumen_vehiculos;

                                 me.data_excel=me.resumen_vehiculos;







                                 me.ocultarLoading();
                                 me.dialogInicio=false;





                            }).catch(e=>{ console.log(e) });


           },



      

         AbrirDetalleVeh(item){

            this.viewVeh={'nombre':item.nombre,'placa':item.placa};

            console.log(item);

            this.itemDetalleVeh=item.detalle;
            this.dialogoVeh=true;          

      },

    ConvertirFecha(fecha_parametro){
          let texto=new Date(fecha_parametro).toLocaleDateString();
          return(texto);

      },


       getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },


        openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    }



    }
    
}
</script>