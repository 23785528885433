<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Excesos de velocidad</v-toolbar-title>

      <v-spacer></v-spacer>

       <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
                  <v-icon dark>logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>



      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs>

          <v-tabs-slider></v-tabs-slider>

           <v-tab href="#mobile-tabs-5-0" class="primary--text">
            <v-icon>speed</v-icon>
          </v-tab>


           <v-tab href="#mobile-tabs-5-1" class="primary--text">
            <v-icon>terrain</v-icon>
          </v-tab>

          <v-tab href="#mobile-tabs-5-2" class="primary--text">
            <v-icon>analytics</v-icon>
          </v-tab>

         

        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">

        <v-tab-item :value="'mobile-tabs-5-0'">
        <v-card flat>
          <v-card-text>
            <Historicos/>
          </v-card-text>
        </v-card>
      </v-tab-item>


       

         <v-tab-item :value="'mobile-tabs-5-1'">
        <v-card flat>
          <v-card-text>
          <ExcesoGeo/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="'mobile-tabs-5-2'">
        <v-card flat>
          <v-card-text>
          <Resumido/>
          </v-card-text>
        </v-card>
      </v-tab-item>

      
    </v-tabs-items>


     <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
                </v-dialog>


  </v-card>
</template>

<script>

import { mapActions } from "vuex";

import Historicos from '../exceso/Historicos.vue';
import Resumido from '../exceso/Resumido.vue';
import ExcesoGeo from '../exceso/ExcesoGeo.vue';

  export default {
      components:{
        Historicos,Resumido,ExcesoGeo
         },
    data () {
      return {
         dialogExit:false,
         tabs: null
      }
    },
     methods:{

        ...mapActions(['salir']),

         cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              
              this.salir();
            },



    }
  }
</script>