<template>
    <v-flex>
            <v-card>
                    <v-card-title>
                       Historial Excesos
                      <v-divider vertical class="mx-2"></v-divider>

                       <v-flex md2 lg2 mx-4 pt-4>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
                      </v-flex>

                       <v-btn dark color="success" @click="dialogInicio=true" class="mx-1">
                            <v-icon>date_range</v-icon>
                            </v-btn>


                       <vue-excel-xlsx  class="mx-1" v-show="data_excel.length >0"
                        :data="data_excel"
                        :columns="column_excel"
                        :filename="'Historial_excesos'"
                        :sheetname="'hoja1'">
                        <v-btn color="info">
                            <v-icon dark>get_app</v-icon>
                        </v-btn>
                        
                        </vue-excel-xlsx>

                   
                           
                       <v-spacer></v-spacer>


                   

                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>

                    
            <v-data-table
                :headers="headers"
                :items="ArregloExcesos"
                :items-per-page="5"
                class="elevation-1"
                :search="search"
            >
            
             <template v-slot:[`item.fecha`]="{ item }"> 
               <span>{{getDate(item.fecha)}} </span>
             </template>

              <template v-slot:[`item.ubicacion`]="{ item }">
                        <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
              </template>




              





            </v-data-table>
         </v-card>


         <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Excesos por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>


           <v-flex>
      
                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="listar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
</v-dialog>


    </v-flex>
</template>



<script>

import axios from 'axios';
import { mapMutations, mapState } from "vuex";


  export default {
    data: () => ({
        headers: [
          { text: 'Vehiculo', value: 'id_vehiculo.nombre' },
          { text: 'Placa', value: 'id_vehiculo.placa' },
          { text: 'Flota', value: 'id_vehiculo.flota' },
          { text: 'Conductor', value: 'id_conductor.nombre_conductor' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Evento', value: 'evento' },
          { text: 'Velocidad', value: 'velocidad' },
          { text: 'Severidad', value: 'severidad'},
          { text: 'Ubicacion', value: 'ubicacion' },
          { text: 'Direccion', value: 'direccion' },
        ],
        ArregloExcesos: [],


         menu: false,
         dialogInicio:false,

        menuFecha1:false,
        fecha_inicio:new Date().toISOString().substr(0, 10),
        menuFecha2:false,
        fecha_fin:new Date().toISOString().substr(0, 10),
        date: new Date().toISOString().substr(0, 10),


        data_excel:[],

        column_excel:[ 
                    {label: "Vehiculo",  field: "vehiculo"},
                    {label: "Placa", field: "placa"},
                    {label: "Flota", field: "flota"},

                    {label: "Cantidad",  field: "conductor"},
                    {label: "Fecha", field: "fecha"},
                    {label: "Evento", field: "evento"},
                    {label: "Velocidad", field: "velocidad"},
                    {label: "Severidad", field: "severidad"},
                    {label: "Direccion",  field: "direccion"}
         ],

         
         id_pais_activo:'',
         PaisesArray:[],
         search:'',

         RespuestaPaises:[],

         arrayFlota:[],



      }), computed: {

          ...mapState(['usuario', 'token']),
      },
      
      created () {
      this.listarPaises();
      
    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),


       

           listarPaises () {

                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'Accediendo a datos'});

                let me=this;

            me.RespuestaPaises=[];

          axios.get('paises',  config
          ).then(function(response){
             console.log(response);
             me.RespuestaPaises=response.data;

             me.RespuestaPaises.map(function(x){
                  me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                });

             me.id_pais_activo=me.PaisesArray[0].value;

             me.ocultarLoading();

            
             me.listarFlotas();
            
              
          }).catch(function(error){
            console.log(error);
          });

         


           },

              //cada flota tiene su clasificacion de severidad
         listarFlotas(){

                     this.arrayFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`flotas/${this.id_pais_activo}`,
                    config
                    ).then(function(response){
                      
                        me.arrayFlota=response.data;

                        me.ocultarLoading();
                         me.listar();

                          console.log(me.arrayFlota);
                    }).catch(function(error){ console.log(error); });

         },



          listar(){


                 //seleccionamos clasificaicon de velocidad en relacion a pais seleccionado
                let me=this;

                

            
               this.ArregloExcesos=[];
           
               let config={headers:{ token:this.token}};
               
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('eventos',{ 
                                'id_pais':this.id_pais_activo,
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                                'tipo':'Exceso'
                              },
                            config
                            ).then(response=>{
                            
                                console.log(response);

                                let respuesta=response.data;


 
                                  me.data_excel=[];

                                   respuesta.map(function(x){


                                     let clasificacion=[];


                                     if((x.id_vehiculo) && (x.id_vehiculo.flota!=null) && (x.velocidad > 79)){

                                          //buscamos la segmentacion para esa flota de ese evento
                                          me.arrayFlota.map(function(b){
                                            if(b.nombre_flota==x.id_vehiculo.flota){
                                                clasificacion=b.clasificacion_velocidad;
                                            }
                                            });
                                        


                                         let severidad='';

                              

                                        clasificacion.map(function(z){

                                           if((parseFloat(x.velocidad)  >= z.desde)&&(parseFloat(x.velocidad)  <=  z.hasta)){
                                               severidad=z.nombre;
                                               x.severidad=severidad;
                                           }

                                        });




                                        if(severidad!=''){

                                        
                                          me.data_excel.push({ 
                                                    'vehiculo':x.id_vehiculo.nombre,
                                                    'placa':x.id_vehiculo.placa,
                                                    'flota':x.id_vehiculo.flota,
                                                    'conductor':x.id_conductor.nombre_conductor,
                                                    'fecha':me.getDate(x.fecha),
                                                    'evento':x.evento,
                                                    'velocidad':x.velocidad,
                                                    'latitud':x.latitud,
                                                    'longitud':x.longitud,
                                                    'direccion':x.direccion,
                                                    'severidad':severidad,
                                                    }); 

                                        me.ArregloExcesos.push(x);

                                     }


                                      }//fin si hya datos validos




                                          });



                                    me.ocultarLoading();
                                 me.dialogInicio=false;

                         }).catch(e=>{ console.log(e) });

           },


        getDate(evaluar) {
            let date = new Date(evaluar),
            year = date.getFullYear(),
            month = (date.getMonth() + 1).toString(),
            formatedMonth = (month.length === 1) ? ("0" + month) : month,
            day = date.getDate().toString(),
            formatedDay = (day.length === 1) ? ("0" + day) : day,
            hour = date.getHours().toString(),
            formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
            minute = date.getMinutes().toString(),
            formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
            second = date.getSeconds().toString(),
            formatedSecond = (second.length === 1) ? ("0" + second) : second;
            return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
            },


       openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
      },

      DeterminarSeveridad(item){


              let me=this;
              let clasificacion=[];

                  me.RespuestaPaises.map(function(x){
                      if(me.id_pais_activo==x._id){
                          clasificacion=x.clasificacion_velocidad;
                      }
                });


              let severidad='';

                clasificacion.map(function(z){

                    if((item.velocidad  >= z.desde)&&(item.velocidad  <=  z.hasta)){
                        severidad=z.nombre;
                    }

                });

                return(severidad);

      }







    }
    
  }
</script>
